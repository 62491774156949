import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  LOWERCASE,
  NUMBERS,
  PASSWORD_BGCOLORS,
  SYMBOLS,
  UPPERCASE,
} from '../../../../../constants';
import {
  getRandomArray,
  getRandomStringFromArr,
} from '../../../../../Utils/StringUtils';
import Password from './Password';
import PasswordChecker from './PasswordChecker';
import PasswordGeneratorHeader from './PasswordGeneratorHeader';
import PasswordLength from './PasswordLength';

interface PasswordGeneratorProps {}

const INITIAL_PASSWORD_LENGTH = 12;
const INITIAL_RUNNING_STATE = true;

const PasswordGenerator: FC<PasswordGeneratorProps> = ({}) => {
  const theme = useTheme();
  const isTabletAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const [isRunning, setIsRunning] = useState(INITIAL_RUNNING_STATE);
  const [password, setPassword] = useState<string[]>([]);
  const [passwordLength, setPasswordLength] = useState(INITIAL_PASSWORD_LENGTH);
  const [useLowercase, setUseLowercase] = useState(true);
  const [useUppercase, setUseUppercase] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useSymbols, setUseSymbols] = useState(true);
  const [charactersToUse, setCharactersToUse] = useState([
    ...LOWERCASE,
    ...UPPERCASE,
    ...NUMBERS,
    ...SYMBOLS,
  ]);
  const [userInputCharacters, setUserInputCharacters] = useState('');
  const [passwordBgColor, setPasswordBgColor] = useState<string>(
    getRandomStringFromArr(PASSWORD_BGCOLORS)
  );

  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  useEffect(() => {
    setPassword(getRandomArray(charactersToUse, INITIAL_PASSWORD_LENGTH));
  }, []);

  const onUseUppercaseChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setUseUppercase(event.target.checked);
  };

  const onUseLowercaseChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setUseLowercase(event.target.checked);
  };

  const onUseNumbersChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUseNumbers(event.target.checked);
  };

  const onUseSymbolsChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUseSymbols(event.target.checked);
  };

  const onGeneratePasswordHandler = () => {
    setIsRunning(false);
    setPassword(getRandomArray(charactersToUse, passwordLength));
  };

  const onPasswordLengthChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const val = parseInt(event.target.value);
    setPasswordLength(parseInt(event.target.value));

    let tmpArr = [...password];

    const diff = passwordLength - val;

    if (diff < 0) {
      for (let x = 0; x > diff; x--) {
        tmpArr.push(getRandomStringFromArr(charactersToUse));
      }
    } else {
      for (let x = 0; x < diff; x++) {
        tmpArr.pop();
      }
    }

    setPassword([...tmpArr]);
  };

  const onUserInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUserInputCharacters(event.target.value);
  };

  const stopStartInterval = () => {
    setIsRunning((prev) => !prev);
  };

  const updateCharIndex = (index: number, char: string) => {
    setPassword((prev) => {
      const tmpArr = [...prev]; // Make a copy of the current state
      tmpArr[index] = char; // Update the character at the specified index
      return tmpArr; // Return the new array as the updated state
    });
  };

  useEffect(() => {
    // Determine the characters to use
    const updateCharactersToUse = () => {
      const charactersToUse = [
        ...(useLowercase ? LOWERCASE : []),
        ...(useUppercase ? UPPERCASE : []),
        ...(useNumbers ? NUMBERS : []),
        ...(useSymbols ? SYMBOLS : []),
      ];

      if (charactersToUse.length === 0) {
        console.log('HELLO:');
        setUseLowercase(true); // Set LOWERCASE as the default option
        return [...LOWERCASE];
      }
      return charactersToUse;
    };

    if (userInputCharacters && userInputCharacters.length > 0) {
      setCharactersToUse([
        ...userInputCharacters.replaceAll(' ', '').split(''),
      ]);
    } else {
      setCharactersToUse(updateCharactersToUse());
    }
  }, [useUppercase, useNumbers, useSymbols, useLowercase, userInputCharacters]);

  return (
    <Stack direction="column" spacing={4}>
      <PasswordGeneratorHeader />
      <Password
        charactersToUse={charactersToUse}
        isRunning={isRunning}
        password={password}
        passwordBgColor={passwordBgColor}
        updateCharIndex={updateCharIndex}
        useNumbers={useNumbers}
        useUppercase={useUppercase}
        useSymbols={useSymbols}
      />
      <PasswordChecker password={password.join()} />
      <Grid
        container
        direction="row"
        spacing={6}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({ px: 10 })}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({ width: '100%', maxWidth: 1000 })}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <StyledButton variantType="start" onClick={stopStartInterval}>
            {isRunning ? (
              <PauseIcon
                sx={(theme) => ({
                  fontSize: {
                    xs: '2rem',
                    sm: '2.5rem',
                    md: '2.75rem',
                  },
                })}
              />
            ) : (
              <PlayArrowIcon
                sx={(theme) => ({
                  fontSize: {
                    xs: '2rem',
                    sm: '2.5rem',
                    md: '2.75rem',
                  },
                })}
              />
            )}
          </StyledButton>
          <StyledButton
            variantType="generate"
            onClick={onGeneratePasswordHandler}
          >
            <ReplayIcon
              sx={(theme) => ({
                fontSize: {
                  xs: '2rem',
                  sm: '2.5rem',
                  md: '2.75rem',
                },
                transform: 'rotateY(180deg)',
              })}
            />
          </StyledButton>
          <CopyToClipboard onCopy={onCopy} text={password.join('').trim()}>
            <StyledButton variantType="copy">
              <ContentCopyIcon
                sx={(theme) => ({
                  fontSize: {
                    xs: '2rem',
                    sm: '2.5rem',
                    md: '2.75rem',
                  },
                })}
              />
            </StyledButton>
          </CopyToClipboard>
          {!isTabletAndDown && (
            <PasswordLength
              passwordLength={passwordLength}
              onPasswordLengthChangeHandler={onPasswordLengthChangeHandler}
            />
          )}
        </Stack>
      </Grid>
      {isTabletAndDown && (
        <PasswordLength
          passwordLength={passwordLength}
          onPasswordLengthChangeHandler={onPasswordLengthChangeHandler}
        />
      )}
      <Box>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormGroup>
            <Grid
              container
              direction="row"
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={6}
                sm={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {' '}
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={useLowercase}
                      onChange={onUseLowercaseChangeHandler}
                      disabled={
                        !!userInputCharacters && userInputCharacters.length > 0
                      }
                    />
                  }
                  label="Lowercase"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={useUppercase}
                      onChange={onUseUppercaseChangeHandler}
                      disabled={
                        !!userInputCharacters && userInputCharacters.length > 0
                      }
                    />
                  }
                  label="Uppercase"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={useNumbers}
                      onChange={onUseNumbersChangeHandler}
                      disabled={
                        !!userInputCharacters && userInputCharacters.length > 0
                      }
                    />
                  }
                  label="Numbers"
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={useSymbols}
                      onChange={onUseSymbolsChangeHandler}
                      disabled={
                        !!userInputCharacters && userInputCharacters.length > 0
                      }
                    />
                  }
                  label="Symbols"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Stack>
      </Box>
      <Box>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({ px: 2 })}
        >
          {/* <TextField
            id="user-input"
            label="Custom characters to use"
            // variant="standard"
            onChange={onUserInputChangeHandler}
            sx={{
              width: '600px',
              '& .MuiInput-root': {
                height: '80px',
                fontSize: '2.5rem', // Optional: Increase font size for larger text
              },
              '& .MuiOutlinedInput-root': {
                height: '80px',
                fontSize: '2.5rem', // Optional: Increase font size for larger text
              },
              '& .MuiInputLabel-root': {
                fontSize: '1.2rem', // Optional: Increase label font size
              },
            }}
          /> */}
          <FormControl
            variant="outlined"
            fullWidth
            sx={(theme) => ({ maxWidth: '600px' })}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Customer characters to use
            </InputLabel>
            <OutlinedInput
              type="text"
              onChange={onUserInputChangeHandler}
              sx={{
                '& .MuiInput-root': {
                  height: '80px',
                  fontSize: '2.5rem', // Optional: Increase font size for larger text
                },
                '& .MuiOutlinedInput-root': {
                  height: '80px',
                  fontSize: '2.5rem', // Optional: Increase font size for larger text
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.2rem', // Optional: Increase label font size
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Custom characters to use"
            />
          </FormControl>
        </Stack>
      </Box>
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center', // Aligns horizontally
            alignItems: 'center', // Aligns vertically if needed
          })}
        ></Grid>
      </Grid>
    </Stack>
  );
};

interface CustomButtoProps extends ButtonProps {
  variantType: 'start' | 'copy' | 'generate';
}

const StyledButton = styled(Button)<CustomButtoProps>(
  ({ theme, variantType }) => ({
    width: 110,
    color: theme.palette.text.secondary,
    padding: '15px 20px',
    fontSize: '30px',
    fontWeight: 'bold',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#C5E3FC',
    },
  })
);

export default PasswordGenerator;
