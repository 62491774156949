import { useTheme, ThemeContext } from '@emotion/react';
import {
  useMediaQuery,
  Grid,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';
import { ThemeModeSwitch } from '../../../../General/Components';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router-dom';

// Define the interface for the component's props
interface GameHeaderProps {
  count: number;
  openHowToPlayModalHandler: () => void;
}

// Functional Component using props and state
const GameHeader: React.FC<GameHeaderProps> = ({
  count,
  openHowToPlayModalHandler,
}) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleNavigateHomeClick = () => {
    navigate('/games'); // Specify the route you want to navigate to
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({ py: 1 })}
    >
      <Grid item xs={3} sx={(theme) => ({ textAlign: 'left' })}>
        <IconButton
          aria-label="search"
          color="primary"
          onClick={handleNavigateHomeClick}
          size="large"
          sx={{ transform: 'rotateY(180deg)', padding: 2 }}
        >
          <ExitToAppIcon sx={{ fontSize: '2rem', fontWeight: 'lighter' }} />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={6}
        sx={(theme) => ({
          textAlign: 'center',
          opacity: 1,
        })}
      >
        <Typography
          variant="h2"
          sx={{
            mt: 0,
            textAlign: 'center',
            fontWeight: 500,
            color: '#333',
          }}
        >
          {count}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={(theme) => ({ textAlign: 'right' })}>
        <Stack direction="row" spacing={0} justifyContent="flex-end">
          <ThemeModeSwitch show={false} />
          <IconButton
            aria-label="search"
            color="primary"
            onClick={openHowToPlayModalHandler}
          >
            <QuestionMarkIcon sx={{ fontSize: '2.35rem' }} />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default GameHeader;
