import { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NotFound from './components/Layout/Public/404';
import Calculator from './components/Layout/Public/Calculator/Calculator';
import Conversion from './components/Layout/Public/Conversion/Conversion';
import Facts from './components/Layout/Public/Facts/Facts';
import Gallery from './components/Layout/Public/Gallery/Gallery';
import Page from './components/Layout/Public/Page';
import PublicPage from './components/Layout/Public/PublicPage';
import UnderConstruction from './components/Layout/Public/UnderConstruction';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
// import { GoogleAuthProvider, getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import Banking from './components/Layout/Private/Banking/Banking';
import Login from './components/Layout/Private/Login';
import CountdownTimer from './components/Layout/Public/Apps/CountdownTimer/CountdownTimer';
import Main from './components/Layout/Public/Main';
import TaskList from './components/Layout/Public/Tasks/TaskList';
import Home from './components/Layout/Public/Bio/Home';
import ReleaseNotes from './components/Layout/Public/ReleaseNotes/ReleaseNotes';
import AppsHome from './components/Layout/Public/Apps/AppsHome';
import UnityGame from './components/Games/UnityGame';
import SafeRoute from './components/General/SafeRoute';
import NumberRecognitionGame from './components/Layout/Public/Apps/NumberRecognitionGame/NumberRecognitionGame';
import BoxShadowExamples from './components/Layout/Public/BoxShadowExamples/BoxShadowExamples';
import BreakpointIndicator from './components/Layout/Public/BoxShadowExamples/BreakpointIndicator';
import IsLandscapeCheck from './components/General/IsLandscapeCheck';
import PasswordGenerator from './components/Layout/Public/Apps/PasswordGenerator/PasswordGenerator';
// import TaskList from "./components/Layout/Public/Tasks/TaskList";

const firebaseConfig = {
  apiKey: 'AIzaSyDJEgf-9mUMXfzbEtrTLc-VFqO6KneAeY4',
  authDomain: 'everythingstevens-8a3f4.firebaseapp.com',
  projectId: 'everythingstevens-8a3f4',
  storageBucket: 'everythingstevens-8a3f4.appspot.com',
  messagingSenderId: '615866915981',
  appId: '1:615866915981:web:edb011560596b78a7616c4',
  measurementId: 'G-HSPH9T8JSS',
};

const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
// Create a storage reference from our storage service
export const storageRef = ref(storage);
// export const auth = getAuth(app);
// export const googleProvider = new GoogleAuthProvider();

// Get a list of cities from your database
// async function getCities(db) {
//   const citiesCol = collection(db, "cities");
//   const citySnapshot = await getDocs(citiesCol);
//   const cityList = citySnapshot.docs.map((doc) => doc.data());
//   return cityList;
// }

const App = () => {
  logEvent(analytics, 'web_app_loaded');

  useEffect(() => {
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/firebase.User
    //     const uid = user.uid;
    //     // ...
    //     console.log('uid', uid);
    //   } else {
    //     // User is signed out
    //     // ...
    //     console.log('user is logged out');
    //   }
    // });
  }, []);

  return (
    <Fragment>
      <BrowserRouter>
        {/* <BreakpointIndicator /> */}
        <Routes>
          {/* Public Pages */}
          <Route
            path="/"
            element={
              <SafeRoute
                element={
                  <Page showHeader={false}>
                    <Main />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/main"
            element={
              <SafeRoute
                element={
                  <Page showHeader={false}>
                    <Main />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/home"
            element={
              <SafeRoute
                element={
                  <Page>
                    <Home />
                  </Page>
                }
              />
            }
          />
          {/* <Route
            path="/public"
            element={<SafeRoute element={<PublicPage text="test" />} />}
          /> */}
          <Route
            path="/blog"
            element={
              <SafeRoute
                element={
                  <Page>
                    <UnderConstruction text="Blog" />
                    {/* <Blog /> */}
                  </Page>
                }
              />
            }
          />
          <Route
            path="/gallery"
            element={
              <SafeRoute
                element={
                  <Page>
                    <Gallery />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/conversion"
            element={
              <SafeRoute
                element={
                  <Page>
                    <Conversion />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/calculator"
            element={
              <SafeRoute
                element={
                  <Page>
                    <Calculator />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/facts"
            element={
              <SafeRoute
                element={
                  <Page>
                    <Facts />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/tasks"
            element={
              <SafeRoute
                element={
                  <Page>
                    {/* <UnderConstruction text="Task List" /> */}
                    <TaskList />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/games"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    <AppsHome />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/games/countdown-timer"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    <CountdownTimer />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/games/pathfinder"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    {/* <UnityGame /> */}
                    <UnderConstruction text="PathFinder" />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/games/number-recognition"
            element={
              <SafeRoute
                element={
                  <Page showHeader={false}>
                    <IsLandscapeCheck>
                      <NumberRecognitionGame />
                    </IsLandscapeCheck>
                  </Page>
                }
              />
            }
          />
          <Route
            path="/box-shadows"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    <BoxShadowExamples />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/password-generator"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    <PasswordGenerator />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/releases"
            element={
              <SafeRoute
                element={
                  <Page showHeader={true}>
                    <ReleaseNotes />
                  </Page>
                }
              />
            }
          />
          {/* Private Pages */}
          {/* <Route path="/private" element={<Contact />}></Route> */}
          <Route
            path="/login"
            element={
              <SafeRoute
                element={
                  <Page showFooter={false}>
                    <Login />
                  </Page>
                }
              />
            }
          />
          <Route
            path="/banking"
            element={
              <SafeRoute
                element={
                  <Page>
                    {/* <UnderConstruction text="Task List" /> */}
                    <Banking />
                  </Page>
                }
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
