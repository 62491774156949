import { Slider, Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface PasswordLengthProps {
  passwordLength: number;
  onPasswordLengthChangeHandler: (event: any) => void;
  min?: number;
  max?: number;
  step?: number;
}

const PasswordLength: FC<PasswordLengthProps> = ({
  passwordLength,
  onPasswordLengthChangeHandler,
  min = 4,
  max = 30,
  step = 1,
}) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{ width: '100%', mb: 1, px: 2 }}
      alignItems="center"
    >
      <Typography variant="h5">Length</Typography>
      <Slider
        aria-label="Password Length"
        value={passwordLength}
        onChange={onPasswordLengthChangeHandler}
        valueLabelDisplay="auto"
        step={step}
        marks
        min={min}
        max={max}
      />
      <Typography variant="h5">{passwordLength}</Typography>
    </Stack>
  );
};

export default PasswordLength;
