import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { checkPasswordComplexity } from 'check-password-complexity';
import { FC, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../../themes/MyThemeProvider';
import { BorderLinearProgress } from '../CountdownTimer/CountdownTimer';

interface PasswordCheckerProps {
  password: string;
}

const PasswordChecker: FC<PasswordCheckerProps> = ({ password }) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [passwordStrengthVal, setPasswordStrengthVal] = useState<number>(0);
  const [passwordStrengthCol, setPasswordStrengthCol] = useState<string>(
    theme.palette.error.main
  );

  useEffect(() => {
    const pwdStrengthVal = checkPasswordComplexity(password).value;
    switch (pwdStrengthVal) {
      case 'tooWeak':
        setPasswordStrengthVal(25);
        setPasswordStrengthCol(theme.palette.error.main);
        break;
      case 'weak':
        setPasswordStrengthVal(50);
        setPasswordStrengthCol(theme.palette.warning.main);
        break;
      case 'medium':
        setPasswordStrengthVal(75);
        setPasswordStrengthCol(theme.palette.primary.main);
        break;
      case 'strong':
        setPasswordStrengthVal(100);
        setPasswordStrengthCol(theme.palette.success.main);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => ({ width: '100%' })}
    >
      <Stack
        direction="column"
        spacing={4}
        sx={(theme) => ({
          px: 6,
          width: '100%',
          maxWidth: 1200,
          margin: 'auto',
        })}
      >
        <BorderLinearProgress
          variant="determinate"
          value={passwordStrengthVal}
          sx={(theme) => ({
            height: 20,
            '& .MuiLinearProgress-bar': {
              bgcolor: passwordStrengthCol,
            },
            '&.MuiLinearProgress-colorPrimary': {
              backgroundColor: 'efefef',
            },
            left: '-3px',
            width: '100%',
          })}
        />
      </Stack>
    </Box>
  );
};

export default PasswordChecker;
