import { useTheme, ThemeContext } from '@emotion/react';
import { useMediaQuery, Box } from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';

// Define the interface for the component's props
interface NumberTileProps {
  flash: boolean;
  greenFlash: boolean;
  el: any;
  index: number;
  handleClick: (el: any, index: number) => void;
}

// Functional Component using props and state
const NumberTile: React.FC<NumberTileProps> = ({
  flash,
  greenFlash,
  el,
  index,
  handleClick,
}) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={(theme) => ({
        fontSize: { xs: '1.0rem', sm: '1.5rem', md: '2rem' },
        position: 'relative',
        width: { xs: 10, sm: 40, md: 80 },
        height: { xs: 10, sm: 40, md: 80 },
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        fontWeight: 500,
        transition:
          'font-size 0.15s ease-out, font-weight 0.3s ease-out, background-color 0.15s ease-out',
        borderRadius: '8px',
        backgroundColor: flash
          ? greenFlash
            ? 'rgba(22, 219, 147, 0.2)'
            : 'rgba(239, 121, 138, 0.1)'
          : '',
        '&:hover': {
          fontSize: { sm: '2.4rem', md: '3rem' },
          fontWeight: 700,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: 0,
          width: 0,
          height: '2px',
          backgroundColor: '#1976d2',
          transition: '0.3s',
        },
        '&:hover::before': {
          width: '100%',
        },
      })}
      onClick={() => handleClick(el, index)}
    >
      {el}
    </Box>
  );
};

export default NumberTile;
