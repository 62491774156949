import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NUMBER_QUESTIONS } from '../../../../../constants';
import QuestionOverlayBox from './QuestionOverlayBox';

interface QuestionsContainerProps {
  items: any;
  question: string;
  flash: boolean;
  greenFlash: boolean;
  addQuestionToArrayHandler: () => void;
  setNumberQuestionHandler: () => void;
}

const startTime = 8;

const QuestionsContainer: React.FC<QuestionsContainerProps> = ({
  items,
  addQuestionToArrayHandler,
  setNumberQuestionHandler,
}) => {
  const theme = useTheme();
  const [indexToRun, setIndexToRun] = useState<number>(-1);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [translateBoxX, setTranslateBoxX] = useState(0);
  const [time, setTime] = useState<number>(startTime); // Timer value
  const [textColor, setTextColor] = useState(theme.palette.primary.main);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          setTranslateBoxX((prevPosition) => prevPosition - 500); // Example of box movement
          setIndexToRun((prev) => prev + 1); // Increment indexToRun
          setTime(startTime); // Reset the timer
          addQuestionToArrayHandler();
          setTextColor('#ddd');
          setNumberQuestionHandler();
          return startTime; // Reset the timer value
        }
        return prevTime - 1; // Decrement the timer
      });
    }, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup the interval on component unmount
  }, []); // The empty dependency array ensures this runs only once on mount

  useEffect(() => {
    // Trigger the opacity change after the component mounts
    setOverlayVisible(true);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        transform: {
          xs: 'translateX(1%)',
          md: 'translateX(32%)',
          lg: 'translateX(36%)',
          xl: 'translateX(44%)',
        },
        // borderBottom: `2px solid ${theme.palette.primary.main}`,
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          backgroundColor: '#fff',
          zIndex: 2,
        }}
      />

      {/* Scrolling Container */}
      <Box
        sx={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            transition: 'transform 1s ease',
            transform: `translateX(${translateBoxX}px)`,
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              sx={(theme) => ({
                color: '#fff',
                height: '50px',
                width: '400px', // Adjust width as needed
                minWidth: '400px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative', // Allows absolute positioning for overlay
                // borderTop:
                //   index < indexToRun + 1
                //     ? `2px solid ${theme.palette.containerPrimary?.dark}`
                //     : `2px solid ${theme.palette.primary.main}`,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                // border:
                //   index < indexToRun + 1
                //     ? `2px solid ${theme.palette.containerPrimary?.light}`
                //     : // : `2px solid ${theme.palette.primary.main}`,
                //       `2px solid #cfcfcf`,
                borderBottom: 'none',
                overflow: 'hidden',
                mr: '100px',
                // bgcolor: '#1976d21c',
                bgcolor: theme.palette.containerSecondary?.light,
                // boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px -1.95px 2.6px',
              })}
            >
              {/* Overlay box */}
              <QuestionOverlayBox
                index={index}
                indexToRun={indexToRun + 1}
                overlayVisible={overlayVisible}
                startTime={startTime}
              />

              {/* Text content */}
              <Typography
                variant="h5"
                sx={(theme) => ({
                  position: 'relative', // Ensures it displays above the grey overlay
                  zIndex: 2, // Higher z-index to stay above overlay
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  color:
                    index < indexToRun + 1
                      ? theme.palette.containerPrimary?.light
                      : theme.palette.primary.main,
                  // : theme.palette.primary.main,
                  backgroundColor: 'transparent', // Keep background transparent for text
                })}
              >
                {NUMBER_QUESTIONS[item].label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionsContainer;
