import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';

// Define the interface for the component's props
interface IsLandscapeCheckProps {
  children: any;
}

// Functional Component using props and state
const IsLandscapeCheck: FC<IsLandscapeCheckProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight // Corrected check for landscape orientation
  );

  const handleResize = () => {
    setIsLandscape(window.innerWidth > window.innerHeight); // Corrected check on resize
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {isMobile && isLandscape && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            textAlign: 'center',
            padding: '20px',
          }}
        >
          <p>
            Please rotate your device to portrait mode for the best experience.
          </p>
        </div>
      )}
      <div style={{ opacity: isLandscape && isMobile ? 0.5 : 1 }}>
        {children}
      </div>
    </div>
  );
};

export default IsLandscapeCheck;
