import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import beach2 from '../../resources/images/beach2.jpg';
import images from '../../Utils/ImagesUtils';

type CardImageTextProps = {
  title?: string;
  description?: string;
  link?: string;
};

export const CardImageTextCard = styled(Card)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default}`,
}));

const CardImageText = ({ title, description, link }: CardImageTextProps) => {
  const keysArray = Object.keys(images);
  const randomIndex = Math.floor(Math.random() * keysArray.length);
  const randomKey = keysArray[randomIndex];

  return (
    <CardImageTextCard sx={{ width: '100%', minHeight: '120px' }}>
      {/* <Link href={link?.toLocaleLowerCase()} underline="none"> */}
      <CardActionArea>
        {/* <CardMedia
          component="img"
          height="170"
          src={images[randomKey]}
          alt={beach2}
        /> */}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="text.primary"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={(theme) => ({ fontSize: '1.2rem', textAlign: 'left' })}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* </Link> */}
    </CardImageTextCard>
  );
};

export default CardImageText;
