import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContextQuery } from '../../../themes/MyThemeProvider';
import {
  ThemeComponents,
  ThemeModeSwitch,
  ThemeSwitch,
  ToggleShowThemeComp,
} from '../Components';
import './Navbar.css';

// Define the interface for the component's props
interface NavbarProps {
  initialValue?: number;
  title?: string;
}

const pages = [
  'Home',
  //   'Blog',
  //   'Gallery',
  'Calculator',
  //   'Facts',
  'Conversion',
  'Tasks',
  //   'Banking',
  'Games',
  'Box-Shadows',
  'Password-Generator',
  'Releases',
];

// Functional Component using props and state
const Navbar: React.FC<NavbarProps> = ({ initialValue = 10, title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tcq = useContext(ThemeContextQuery);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: '300px' }} role="presentation">
      <List>
        <ListItem
          key="themeButton"
          disablePadding
          sx={{ justifyContent: 'center' }}
        >
          <ThemeModeSwitch show={false} />
          {/* <ThemeSwitch show={false} />
          <ToggleShowThemeComp /> */}
        </ListItem>
      </List>
      <Divider />
      <List>
        {pages.map((page, index) => (
          <ListItem key={page} disablePadding>
            <NavbarLink2
              href={'/' + page.toLocaleLowerCase()}
              sx={{
                mx: 'auto 0px',
                width: '100%',
              }}
            >
              <ListItemButton
                color="primary"
                sx={{ justifyContent: 'center' }}
                onClick={toggleDrawer(false)}
              >
                {page}
              </ListItemButton>
            </NavbarLink2>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            position: 'relative',
            flexGrow: 1,
            padding: { xs: '8px 5%', md: '0 10%' },
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.palette.containerPrimary?.main,
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 100,
            boxShadow: 'none',
            // borderBottom: `2px solid ${theme.palette.primary.main}`,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: 0,
              marginX: '10%',
              width: '80%',
              backgroundColor: theme.palette.primary.main,
              transition: '0.3s',
              textAlign: 'center',
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={(theme) => ({
              textAlign: 'none',
              // borderBottom: `2px solid ${theme.palette.primary.main}`,
            })}
          >
            <Box sx={{ display: { xs: 'flex', md: 'flex', lg: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="primary"
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
            </Box>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link href="/" underline="none">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: '24px', // Font size on extra-small screens (0px - 599px)
                        sm: '24px', // Font size on small screens (600px - 899px)
                        md: '24px', // Font size on medium screens (900px - 1199px)
                        lg: '24px', // Font size on large screens (1200px - 1535px)
                        xl: '32px', // Font size on extra-large screens (1536px+)
                      },
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    Everything Stevens
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                sx={{ display: { xs: 'none', md: 'none', lg: 'flex' } }}
              >
                <div>
                  {/* DESKTOP LINKS!! */}
                  {pages.map((page, index) => (
                    <NavbarLink2
                      key={index}
                      href={'/' + page.toLocaleLowerCase()}
                      sx={{
                        marginLeft: '40px',
                      }}
                      color="primary"
                    >
                      {page}
                    </NavbarLink2>
                  ))}
                </div>
              </Grid>
            </Grid>

            <Box
              sx={(theme) => ({
                flexGrow: 1,
                justifyContent: 'space-between',
                display: { xs: 'none', md: 'flex' },
                ml: '20px',
                // borderLeft: `2px solid ${theme.palette.primary.main}`,
              })}
            >
              {' '}
              {!isMobile && <ThemeModeSwitch show={false} />}
              {/* {!isMobile && <ThemeSwitch show={false} />} */}
              {/* {!isMobile && <ToggleShowThemeComp />} */}
            </Box>
          </Toolbar>
        </AppBar>
        {tcq.showThemeComp && <ThemeComponents />}
      </Box>
    </>
  );
};

export const NavbarLink2 = styled(Link)(({ theme }) => ({
  position: 'relative',
  fontSize: '15px',
  color: theme.palette.primary.main,
  fontWeight: 500,
  // mx: 'auto 0px',
  textDecoration: 'none',
  marginLeft: '20px !important',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: 0,
    width: 0,
    height: '2px',
    backgroundColor: '#1976d2',
    transition: '0.3s',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% + 3px)', // Adjust the spacing below the first line
    right: 0,
    width: 0,
    height: '2px',
    backgroundColor: theme.palette.primary.main, // Choose a different color if desired
    transition: '0.5s',
  },
  '&:hover::before': {
    width: '100%',
  },
  '&:hover::after': {
    width: '100%',
  },
}));

export default Navbar;
