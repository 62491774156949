import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface QuestionOverlayBoxProps {
  overlayVisible?: boolean;
  indexToRun: number;
  index: number;
  startTime: number;
}

const QuestionOverlayBox: React.FC<QuestionOverlayBoxProps> = ({
  overlayVisible,
  indexToRun,
  index,
  startTime,
}) => {
  const [initialVisible, setInitialVisible] = useState(overlayVisible);
  const [aniDone, setAniDone] = useState(false);

  // Start transition after initial render and reset width when timer resets
  useEffect(() => {
    if (initialVisible !== overlayVisible) {
      setInitialVisible(overlayVisible); // Trigger transition
    }
  }, [overlayVisible]); // Trigger every t

  const onTransitionEnd = () => {
    setAniDone(true);
  };

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        width: !aniDone
          ? initialVisible && index === indexToRun
            ? '0%'
            : '100%'
          : '0%', // Transition between 100% and 0%
        height: '100%',
        // bgcolor: theme.palette.containerSecondary?.light,
        bgcolor: 'rgba(25, 118, 210, 0.2)',
        // borderLeft:
        //   index < indexToRun
        //     ? `1px solid ${theme.palette.containerPrimary?.dark}`
        //     : `1px solid ${theme.palette.primary.main}`,
        // borderRight: `3px solid #ddd`,
        top: 0,
        left: 0,
        zIndex: 1, // Ensure it overlays the background
        transition: initialVisible && !aniDone && `width ${startTime}s linear`,
        borderTopLeftRadius: 10,
        // borderTopRightRadius: 10,
      })}
      onTransitionEnd={onTransitionEnd}
    />
  );
};

export default QuestionOverlayBox;
