import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../../../Utils/LocalStorageUtils';
import {
  evaluateEquation,
  generateRandomRange,
  getRandomNumber,
  getRandomNumbers,
} from '../../../../../Utils/NumberUtils';
import {
  EVERYTHING_STEVENS_PREFIX,
  NUMBER_QUESTIONS,
  NUMBER_RECOGNITION_GAME_PREFIX,
  STATISTIC,
} from '../../../../../constants';
import { BorderLinearProgress } from '../CountdownTimer/CountdownTimer';
import HowToPlayModal from '../CountdownTimer/HowToPlayModal';
import GameModal from '../GameModal';
import GameHeader from './GameHeader';
import NumberTile from './NumberTile';
import QuestionsContainer from './QuestionsContainer';

interface NumberRecognitionGameProps {}

// const startTimer = getRandomNumber(20, 30);
const startTimer = 90;

const game_highscore_key =
  EVERYTHING_STEVENS_PREFIX +
  NUMBER_RECOGNITION_GAME_PREFIX +
  STATISTIC +
  'highscore';

const INITIAL_QUESTIONS = generateRandomRange(
  0,
  NUMBER_QUESTIONS.length - 1,
  8
);

const NumberRecognitionGame: FC<NumberRecognitionGameProps> = () => {
  const [count, setCount] = useState(100);
  const [randomNumbers, setRandomNumbers] = useState(
    getRandomNumbers(-50, 50, 60)
  );
  const [numberQuestion, setNumberQuestion] = useState({});
  const [progress, setProgress] = useState(100);
  const animationIdRef = useRef<number | null>(null);
  const [seconds, setSeconds] = useState<number>(startTimer);
  const [highscore, setHighscore] = useState(
    parseInt(getItemFromLocalStorage(game_highscore_key) ?? '') || 0
  );
  const [isGameOver, setIsGameOver] = useState(false);
  const [flash, setFlash] = useState<boolean>(false);
  const [greenFlash, setGreenFlash] = useState<boolean>(true);
  const [flashingIndex, setFlashingIndex] = useState<number>(0);
  const [questionItems, setQuestionItems] = useState(INITIAL_QUESTIONS);
  const [questionIndex, setQuestionIndex] = useState(0);

  const addQuestionToArrayHandler = () => {
    const nextQuestionIndex = getRandomNumber(0, NUMBER_QUESTIONS.length - 1);

    let tempArr = questionItems;
    tempArr.push(nextQuestionIndex);
    setQuestionItems([...tempArr]);
  };

  //How to Play
  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(false);
  const openHowToPlayModalHandler = () => {
    setIsHowToPlayModalOpen((prev) => !prev);
  };

  const handleFlashClick = (value: boolean) => {
    setFlash(true);
    if (value) {
      setGreenFlash(true);
    } else {
      setGreenFlash(false);
    }

    // Reset flash state after 500ms to remove the green color
    setTimeout(() => setFlash(false), 200);
  };

  useEffect(() => {
    setQuestionItems(INITIAL_QUESTIONS);
    setNumberQuestion(NUMBER_QUESTIONS[questionItems[0]]);

    // Function to handle the animation frame
    const updateProgress = (timestamp: number) => {
      const elapsed = timestamp - startTimer; // Calculate elapsed time since animation started
      const newProgress = Math.max(
        100 - (elapsed / 1000) * (100 / startTimer),
        0
      ); // Decrease over time, at 10% per second

      setProgress(newProgress);

      // Continue the animation as long as progress is greater than 0
      if (newProgress > 0) {
        animationIdRef.current = requestAnimationFrame(updateProgress);
      }
    };

    // Start the animation
    const id = requestAnimationFrame(updateProgress);

    // Cleanup function to stop the animation when the component unmounts
    return () => cancelAnimationFrame(id);
  }, []);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (seconds >= 0) {
        setSeconds((prevSeconds) => {
          const newSeconds = prevSeconds - 1;
          if (newSeconds < 0) return 0;
          return newSeconds;
        });
      } else {
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once

  const handleClick = (value, index) => {
    setFlashingIndex(index);
    onNumberClickedHandler(value, index);
  };

  const onNumberClickedHandler = (value: number, index: number) => {
    setFlashingIndex(index);
    console.log(`${value} with ${numberQuestion.eq}`);
    let equation = `${value} ${numberQuestion.eq}`;
    console.log(
      `${value} ${numberQuestion.eq} = ${evaluateEquation(equation)}`
    );
    if (evaluateEquation(equation)) {
      handleFlashClick(true);
      setCount((prev) => prev + Math.abs(value));

      // update numbers index with new number
      const randNum = getRandomNumber(-50, 50);
      const tempArr = randomNumbers;
      tempArr[index] = randNum;
      setRandomNumbers(tempArr);
    } else {
      handleFlashClick(false);
      setCount((prev) => prev - Math.abs(value));
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setIsGameOver(true);
      setIsHowToPlayModalOpen(false);

      if (count > highscore) {
        addItemToLocalStorage(game_highscore_key, count);
        setHighscore(count);
      }
    }
  }, [seconds]);

  const setNumberQuestionHandler = () => {
    setQuestionIndex((prev) => {
      const newIndex = prev + 1;
      setNumberQuestion(NUMBER_QUESTIONS[questionItems[newIndex]]);
      return newIndex;
    });
  };

  const numberTiles = randomNumbers.map((el, index) => {
    return (
      <Grid
        key={index}
        item
        xs={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: 3,
          cursor: 'pointer',
          p: { xs: '2px !important', sm: '8px !important' },
        }}
      >
        <NumberTile
          flash={flash}
          greenFlash={greenFlash}
          el={el}
          index={index}
          handleClick={handleClick}
        />
      </Grid>
    );
  });

  return (
    <Box sx={{ overflowX: 'hidden', width: '100%' }}>
      <Grid container direction="column">
        <Grid item xs={6} sx={{ textAlign: 'center', pt: '0px !important' }}>
          <GameHeader
            count={count}
            openHowToPlayModalHandler={openHowToPlayModalHandler}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={0}
        sx={(theme) => ({
          position: 'relative',
        })}
      >
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            textAlign: 'center',
            width: '100%',
            overflow: 'hidden',
          })}
        >
          <QuestionsContainer
            items={questionItems}
            question={numberQuestion.label}
            flash={flash}
            greenFlash={greenFlash}
            addQuestionToArrayHandler={addQuestionToArrayHandler}
            setNumberQuestionHandler={setNumberQuestionHandler}
          />
        </Grid>
      </Grid>
      <Box
        sx={(theme) => ({
          // borderTop: `2px solid ${theme.palette.primary.main}`,
          borderTop: `4px solid #ddd`,
          // borderBottom: `2px solid ${theme.palette.primary.main}`,
        })}
      >
        <BorderLinearProgress
          variant="determinate"
          value={progress}
          sx={(theme) => ({
            height: 10,
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.primary.main,
            },
            '&.MuiLinearProgress-colorPrimary': {
              // backgroundColor: 'efefef',
              backgroundColor: theme.palette.containerPrimary?.light,
            },
            left: '-3px',
            width: '101%',
          })}
        />
      </Box>
      <Box sx={(theme) => ({ width: '100%' })}>
        <Grid
          container
          // spacing={4}
          sx={{
            px: { xs: 1, sm: '14%', md: '20%', lg: '32%', xl: '38%' },
            // pt: 4,
            ml: 0,
          }}
        >
          {numberTiles}
        </Grid>
      </Box>
      <GameModal
        isGameOver={isGameOver}
        seconds={seconds}
        userHasWon={false}
        highscore={highscore}
        showMessages={false}
        showTitle={false}
      />
      <HowToPlayModal
        isOpen={isHowToPlayModalOpen}
        setIsHowToPlayModalOpen={setIsHowToPlayModalOpen}
        openHowToPlayModalHandler={openHowToPlayModalHandler}
      >
        <HowToPlayModalChildren highscore={highscore} />
      </HowToPlayModal>
    </Box>
  );
};

// Define the prop types for the component
interface HowToPlayModalChildrenProps {
  highscore: number; // Adjust this type based on the actual data type of highscore
}

const HowToPlayModalChildren: FC<HowToPlayModalChildrenProps> = ({
  highscore,
}) => (
  <Grid item>
    <Grid item sx={{ textAlign: 'center', mb: 1 }}>
      <Typography
        id="transition-modal-title"
        variant="h3"
        sx={{ fontWeight: 500 }}
      >
        {highscore}
      </Typography>
      <Typography
        id="transition-modal-title"
        variant="h6"
        sx={{ fontWeight: 300, mb: 2 }}
      >
        Highscore
      </Typography>
      <Divider
        sx={(theme) => ({
          bgcolor: theme.palette.primary.main,
          height: 1,
        })}
      />
    </Grid>

    <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: '700' }}>
      How To Play
    </Typography>
    <br />
    <Typography variant="body1" sx={{ fontSize: '16px' }}>
      Tap on a number that is associated with the question in the top bar
    </Typography>
    <br />
    <Typography variant="body1" sx={{ fontSize: '16px' }}>
      Each question has a time limit
    </Typography>
    <br />
    {/* <Typography variant="body1" sx={{ fontSize: '16px' }}>
      Tap as many 'correct' numbers in the before you run out of time
    </Typography> */}
    <br />
    <Typography variant="h6">Good luck!</Typography>
  </Grid>
);

export default NumberRecognitionGame;
