import {
  Button,
  Chip,
  Grid,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import henry1 from '../../../../resources/images/profile/henry-1.png';
import henry3 from '../../../../resources/images/profile/henry-3.jpg';
import {
  HomePageTypography,
  Item,
  SectionContainerBox,
} from '../../../General/Components';
// @ts-ignore
// import Fade from "react-awesome-reveal/Fade";
import BackgroundImage from '../../../General/BackgroundImage';
import SocialLinks from '../../../General/Footer/SocialLinks';
import { Fade } from 'react-awesome-reveal';
import { storage } from '../../../../App';
import { getDownloadURL, ref } from 'firebase/storage';
import { useState } from 'react';
import { getFirebaseImage } from '../../../../Utils/FirebaseUtils';

type IntroProps = {
  text?: string;
  backgroundColour: string;
};

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const IntroTypography = styled(Typography)(({ theme }) => ({
  margin: '20px 0px',
  fontSize: '1.2rem !important',
}));

export const CompanyLink = styled('a')(({ theme }) => ({
  // fontFamily: 'Nunito Sans',
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

export const ContactMeButton = styled(Button)(({ theme }) => ({
  color: 'white',
}));

interface ProfileImageContainerProps {
  isMobile?: boolean;
}

export const ProfileImageContainer = styled('div')<ProfileImageContainerProps>(
  ({ theme, isMobile }) => ({
    width: isMobile ? 300 : 400,
    height: isMobile ? 300 : 400,
    marginTop: isMobile ? 20 : 0,
    borderRadius: '50%',
    overflow: 'hidden',
  })
);

export const ProfileImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center center',
}));

const roles = [
  'Solution Architect II',
  'Senior Software Engineer',
  'Full-Stack Software Engineer',
  'Site Reliability Engineer',
  // 'Culture Leader',
];

const Intro = ({ text, backgroundColour }: IntroProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  getFirebaseImage('/profile/henry-3.jpg', setProfileImageUrl);

  return (
    <SectionContainerBox bgcolor={backgroundColour} sx={{ paddingTop: '0px' }}>
      <BackgroundImage />
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} sm={1} md={0} lg={2}></Grid>
        <Grid item xs={12} sm={10} md={5} className="typo-box">
          <div>
            <IntroTypography variant="h6">Hello there, I'm</IntroTypography>
            <div className="test">
              <Typography
                className="name"
                variant="h4"
                style={{ fontWeight: 'bold' }}
              >
                Henry Stevens
              </Typography>
            </div>
            <TypeAnimation
              style={{
                padding: '10px 0px',
                fontSize: '1.5rem',
                display: 'inline-block',
                margin: '10px 0px',
              }}
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "I'm a Solution Architect II",
                1000, // wait 1s before replacing "Mice" with "Hamsters"
                "I'm a Senior Software Engineer",
                1000,
                'I am an innovative thinker',
                1000,
                'I am passionate about UI/UX',
                1000,
                'I am passionate about company culture',
                1000,
                'I was the office DJ (somehow...)',
                1000,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
              preRenderFirstString
              deletionSpeed={82}
            />
            <Typography variant="h6">
              <CompanyLink href="https://fluentcommerce.com/" target="_blank">
                Currently looking for Senior Software Engineer roles (Focusing
                on the front-end)
              </CompanyLink>
            </Typography>
            {/* <Typography variant="body1">
              <br />
              // TODO - Get a job :)
            </Typography> */}
            <Fade>
              <HomePageTypography variant="body1">
                I am a dedicated and passionate software engineer with a strong
                focus on application and web development. I thrive on
                problem-solving, especially when projects require innovative,
                outside-the-box solutions. <br />
                <br />
                In my previous role at Fluent commerce I progressed through
                various roles, starting as a Site Reliability Engineer,
                advancing to Software Engineer, and eventually becoming a
                Solution Architect. Each role provided me with valuable skills,
                expanding my expertise across different domains. As a Solution
                Architect, I leveraged my creative coding abilities to develop
                proof of concepts (POCs) tailored to diverse client
                requirements.
              </HomePageTypography>
            </Fade>
          </div>
          <Typography variant="h6">Previous Roles</Typography>
          <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            style={{
              fontWeight: 'bold',
              marginTop: 2,
              paddingTop: 2,
            }}
          >
            <Fade>
              {roles.map((role, index) => (
                <Grid item>
                  <Item>
                    <Chip
                      label={role}
                      color={index < 4 ? 'primary' : 'secondary'}
                      // color="primary"
                    />
                  </Item>
                </Grid>
              ))}
            </Fade>
          </Grid>

          <div
            style={{
              marginTop: 2,
              float: isMobile ? 'none' : 'left',
            }}
          >
            <SocialLinks />
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          md={5}
          lg={4}
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            display: { xs: 'none', md: 'block' },
          }}
        >
          {profileImageUrl && (
            <Item>
              <ProfileImageContainer isMobile={isMobile}>
                <ProfileImage src={profileImageUrl} alt="Image of Henry" />
              </ProfileImageContainer>
            </Item>
          )}
        </Grid>
        <Grid item xs={12} sm={1} md={0} lg={4}></Grid>
      </Grid>
    </SectionContainerBox>
  );
};

export default Intro;
