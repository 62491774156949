import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ANIMAL_NAMES,
  BOX_COLORS,
  DEFINITIONS,
  TITLES,
} from '../../../../constants';
import { darkenHexColor, hexToRgba } from '../../../../Utils/ColourUtils';
import {
  formatNumberWithCommas,
  getRandomNumber,
  getWeightedRandomNumber,
} from '../../../../Utils/NumberUtils';
import { getRandomStringFromArr } from '../../../../Utils/StringUtils';
import { updateBoxShadowColor } from './BoxShadowExamples';

interface BoxShadowCardProps {
  index: number;
  borderVal: any;
  colorVal: any;
  showBorder: boolean;
  onClick: any;
  handleClick: any;
  isMainCard?: boolean;
  sx: SxProps;
  includeCardStyles: boolean;
}

const style = (theme, isMobile, newBorderVal, showBorder) => ({
  height: 320,
  // width: isMainCard ? '100%' : '',
  width: '100%',
  // minWidth: 320,
  maxWidth: 200,
  padding: 2,
  boxShadow: newBorderVal,
  borderRadius: '5px',
  border: showBorder ? `1px solid #ddd` : 'none',
});

const BaseCard: React.FC<
  BoxShadowCardProps & { children: React.ReactNode; variant?: 'box' | 'card' }
> = ({
  index,
  borderVal,
  colorVal,
  showBorder,
  onClick,
  handleClick,
  isMainCard = false,
  children,
  variant = 'box',
  sx,
  includeCardStyles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const newBorderVal = updateBoxShadowColor(borderVal, colorVal);

  // console.log('showBorder: ', showBorder);

  const handleCardClick = () => {
    if (!isMainCard) {
      onClick(index, newBorderVal);
      handleClick();
    }
  };

  const cardStyles = {
    ...style(theme, isMobile, newBorderVal, showBorder),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  };

  console.log('sx, includeCardStyles: ', sx, includeCardStyles);

  return variant === 'box' ? (
    <Box onClick={handleCardClick} sx={{ ...cardStyles, ...sx }}>
      {children}
    </Box>
  ) : (
    <Card
      onClick={handleCardClick}
      sx={includeCardStyles ? { ...cardStyles, ...sx } : sx}
    >
      {children}
    </Card>
  );
};

export const BoxShadowCard: React.FC<BoxShadowCardProps> = (props) => (
  <BaseCard
    {...props}
    variant="box"
    sx={{
      height: 175,
      padding: 2,
    }}
  >
    <Stack direction="column" spacing={1}>
      <Typography
        variant="h5"
        sx={(theme) => ({ color: theme.palette.text.tertiary })}
      >
        #{props.index + 1}
      </Typography>
    </Stack>
  </BaseCard>
);

export const BoxShadowCardV2: React.FC<BoxShadowCardProps> = (props) => {
  const [randomDefinition] = useState(
    DEFINITIONS[getRandomNumber(0, DEFINITIONS.length - 1)]
  );
  return (
    <BaseCard
      {...props}
      includeCardStyles
      variant="card"
      sx={{ width: '100%', minWidth: 200, maxWidth: 1000 }}
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="space-between"
        sx={{ height: '285px', width: '100%', maxWidth: 320 }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="h5" component="div">
            {randomDefinition.word}
            <Typography sx={{ mt: 0.75 }} color="text.secondary">
              <em>{randomDefinition.partOfSpeech}</em>
            </Typography>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            #{props.index + 1}
          </Typography>
        </Stack>
        <Typography variant="body1">- {randomDefinition.definition}</Typography>
        <Typography variant="body2">"{randomDefinition.example}"</Typography>
        <Link
          href={`https://www.oed.com/search/dictionary/?scope=Entries&q=${randomDefinition.word}`}
          target="_blank"
        >
          <Button size="small">Learn More</Button>
        </Link>
      </Stack>
    </BaseCard>
  );
};

export const BoxShadowCardV3: React.FC<BoxShadowCardProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // console.log('props: ', props);

  const [animalName] = useState(getRandomStringFromArr(ANIMAL_NAMES));
  return (
    <BaseCard
      {...props}
      includeCardStyles={false}
      variant="card"
      sx={{
        bgcolor: '#fff !important',
        height: 320,
        width: isMobile ? '100%' : 320,
        padding: 2,
        boxShadow: props.borderVal,
        borderRadius: 2,
        border: props.showBorder ? `1px solid #ddd` : 'none',
      }}
    >
      <CardMedia
        component="img"
        alt={animalName}
        height="140"
        image={`https://placehold.co/600x400/F8F8F8/EEE?text=${props.index + 1}&font=roboto`}
        sx={(theme) => ({
          p: 0,
          borderTop: '1px solid #ddd',
          borderRight: '2px solid #ddd',
          borderBottom: '2px solid #ddd',
          borderLeft: '1px solid #ddd',
        })}
      />
      <CardContent>
        <Typography gutterBottom variant="h5">
          {animalName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </BaseCard>
  );
};

export const BoxShadowCardV4: React.FC<BoxShadowCardProps> = (props) => {
  const [randNum] = useState(getRandomNumber(0, BOX_COLORS.length - 1));
  const bgColor = hexToRgba(BOX_COLORS[randNum], 0.25);
  const textColor = darkenHexColor(BOX_COLORS[randNum], 40);
  const [title] = useState(getRandomStringFromArr(TITLES));
  const [randomNumber] = useState(getWeightedRandomNumber(100, 9999, 10));

  return (
    <BaseCard
      {...props}
      variant="box"
      sx={{
        width: 275,
        height: 225,
        backgroundColor: bgColor,
        color: textColor,
        textAlign: 'center',
        borderRadius: 4,
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="h5" sx={{ color: textColor }}>
          <b>#{props.index + 1}</b>
        </Typography>
        <Typography variant="h3" sx={{ color: textColor }}>
          {formatNumberWithCommas(randomNumber)}
        </Typography>
        <Typography variant="h5" sx={{ color: textColor }}>
          <b>{title}</b>
        </Typography>
      </Stack>
    </BaseCard>
  );
};
