import { ThemeContext } from '@emotion/react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Fragment, useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import CodeHighlighter from './CodeHighlighter';
import { NavbarLink2 } from '../../../General/NavBar/Navbar';

// Define the interface for the component's props
interface BoxShadowEditorProps {
  isOverlay: boolean;
  selectedCardIndex: number;
  onSelectCardTypeHandler: (value: number) => void;
  setShowBorder: (value: boolean) => void;
  cardStyleString: string;
  sliderValue: number;
  handleSliderChange: (event: Event, newValue: number) => void;
  showBorder: boolean;
  hOffset: number;
  setHOffset: (value: number) => void;
  vOffset: number;
  setVOffset: (value: number) => void;
  blur: number;
  setBlur: (value: number) => void;
  spread: number;
  setSpread: (value: number) => void;
  copyValue: string;
  setCopyValue: (value: string) => void;
}

// Functional Component using props and state
const BoxShadowEditor: React.FC<BoxShadowEditorProps> = ({
  isOverlay,
  selectedCardIndex,
  onSelectCardTypeHandler,
  setShowBorder,
  cardStyleString,
  sliderValue,
  handleSliderChange,
  showBorder,
  hOffset,
  setHOffset,
  vOffset,
  setVOffset,
  blur,
  setBlur,
  spread,
  setSpread,
  copyValue,
  setCopyValue,
}) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [copied, setCopied] = React.useState(false);
  const onChange = React.useCallback(({ target: { value } }) => {
    setCopyValue(value);
    setCopied(true);
  }, []);
  const onClick = React.useCallback(({ target: { innerText } }) => {
    console.log(`Clicked on "${innerText}"!`);
  }, []);
  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 1,
        gap: 2,
      }}
    >
      <Stack
        direction={'column'}
        sx={(theme) => ({ width: '100%' })}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          sx={(theme) => ({ width: '100%' })}
        >
          <Typography>White</Typography>
          <Slider
            aria-label="Volume"
            value={sliderValue}
            onChange={handleSliderChange}
          />
          <Typography>Black</Typography>
        </Stack>

        <Fragment>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="space-between"
            sx={(theme) => ({ py: 2 })}
          >
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Card Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCardIndex}
                  label="Card Type"
                  onChange={(event: SelectChangeEvent) =>
                    onSelectCardTypeHandler(parseInt(event.target.value))
                  }
                >
                  <MenuItem value={0}>Plain & Boring</MenuItem>
                  <MenuItem value={1}>Definitions</MenuItem>
                  <MenuItem value={2}>Card Media</MenuItem>
                  <MenuItem value={3}>Colourful Info</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Switch value={showBorder} checked={showBorder} />}
                label="Show Border"
                onChange={() => {
                  setShowBorder((prev) => !prev);
                }}
                labelPlacement="bottom"
                sx={(theme) => ({
                  width: '100%',
                  maxWidth: '100px',
                })}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                id="h-offset"
                label="h-offset (px)"
                type="number"
                value={hOffset}
                onChange={(event) => setHOffset(Number(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                id="v-offset"
                label="v-offset (px)"
                type="number"
                value={vOffset}
                onChange={(event) => setVOffset(Number(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                id="blur"
                label="blur (px)"
                type="number"
                value={blur}
                onChange={(event) => setBlur(Number(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                id="spread"
                label="spread (px)"
                type="number"
                value={spread}
                onChange={(event) => setSpread(Number(event.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Stack>
          </Stack>
          {!isMobile && (
            <>
              <Divider sx={(theme) => ({ my: 1 })} />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                // alignItems="center"
              >
                <CodeHighlighter cardStyleString={cardStyleString} />
                {/* <Stack direction="row" spacing={2} justifyContent="flex-end"> */}
                <CopyToClipboard onCopy={onCopy} text={copyValue}>
                  <IconButton aria-label="how-to-randomise" onClick={onClick}>
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
                {/* </Stack> */}
              </Stack>
            </>
          )}
          <Stack direction="row" justifyContent="space-between">
            <NavbarLink2
              href="https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow"
              target="_blank"
            >
              MDN Web Docs
            </NavbarLink2>
            <NavbarLink2
              href="https://www.w3schools.com/cssref/css3_pr_box-shadow.php"
              target="_blank"
            >
              W3 Schools
            </NavbarLink2>
            <NavbarLink2
              href="https://css-tricks.com/almanac/properties/b/box-shadow/"
              target="_blank"
            >
              CSS-TRICKS
            </NavbarLink2>
          </Stack>
        </Fragment>
      </Stack>
    </Box>
  );
};

export default BoxShadowEditor;
