import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../themes/MyThemeProvider';
import { getRgbaFromNumber, getRgbaValue } from '../../../../Utils/ColourUtils';
import {
  getRandomNumber,
  getWeightedRandomNumber,
} from '../../../../Utils/NumberUtils';
import {
  BoxShadowCard,
  BoxShadowCardV2,
  BoxShadowCardV3,
  BoxShadowCardV4,
} from './BoxShadowCards';
import BoxShadowEditor from './BoxShadowEditor';

// Define the interface for the component's props
interface BoxShadowExamplesProps {}

const BOX_SHADOW_COUNT = 48;
const INITIAL_COLOR_VALUE = 10;
const INITIAL_CARD_INDEX = 3;

const gridItemsFunc = () => {
  const rgbArr: any[] = [];
  Array.from({ length: BOX_SHADOW_COUNT }, (_, index) => {
    rgbArr.push(
      generateRandomBoxShadow(getRgbaFromNumber(INITIAL_COLOR_VALUE))
    );
  });
  return rgbArr;
};

// TODO have muktiple shadows?
// box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

// Functional Component using props and state4
const BoxShadowExamples: React.FC<BoxShadowExamplesProps> = ({}) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeTablet = useMediaQuery(theme.breakpoints.down('xl'));

  const [sliderValue, setSliderValue] =
    React.useState<number>(INITIAL_COLOR_VALUE);
  const [colorVal, setColorVal] = useState<string | null>(
    getRgbaFromNumber(INITIAL_COLOR_VALUE)
  );
  const [gridItems, setGridItems] = useState<string[]>(gridItemsFunc());
  const [showBorder, setShowBorder] = useState(true);
  const [mainBoxShadow, setMainBoxShadow] = useState(gridItems[0]);
  const [selectedExampleNo, setSelectedExampleNo] = useState<number>(0);
  const [shadowPixels, setShadowPixels] = useState<number[]>([]);

  const [hOffset, setHOffset] = useState(0);
  const [vOffset, setVOffset] = useState(0);
  const [blur, setBlur] = useState(0);
  const [spread, setSpread] = useState(0);

  const [open, setOpen] = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);

  const [selectedCardIndex, setSelectedCardIndex] =
    useState(INITIAL_CARD_INDEX);

  const [cardStyleString, setCardStyleString] = useState<string>(`.box { 
  box-shadow: ${mainBoxShadow.replace(/rgba?\([^)]+\)/, colorVal)};
  border: ${showBorder ? '1px solid #ccc' : 'none'};
}
`);
  const [copyValue, setCopyValue] = useState(cardStyleString);

  const handleSliderChange = (event: Event, newValue: number) => {
    setSliderValue(newValue as number);
    setColorVal(getRgbaFromNumber(newValue));
    setCardStyleString(`.box { 
  box-shadow: ${mainBoxShadow.replace(/rgba?\([^)]+\)/, colorVal)};
  border: ${showBorder ? '1px solid #ccc' : 'none'};
}`);
  };

  const onChildClickUpdateMainHandler = (index: number, value: string) => {
    setSelectedExampleNo(index);
    updateStatePixels(value);

    setColorVal(getRgbaValue(value));
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    let val = `${colorVal} ${hOffset}px ${vOffset}px ${blur}px ${spread}px`;
    setMainBoxShadow(() => {
      setCopyValue(`.box {
box-shadow: ${val};
border: ${showBorder ? '1px solid #ccc' : 'none'};
}`);
      return val;
    });
    setCardStyleString(`.box { 
  box-shadow: ${val};
  border: ${showBorder ? '1px solid #ccc' : 'none'};
}`);
  }, [colorVal, hOffset, vOffset, blur, spread, showBorder]);

  const updateStatePixels = (input: string) => {
    // Extract the pixel values and convert them to integers
    const tempStr = input.match(/-?\d+px/g).map((px) => parseInt(px)) || [];

    setShadowPixels(tempStr);
    setHOffset(tempStr[0]);
    setVOffset(tempStr[1]);
    setBlur(tempStr[2]);
    setSpread(tempStr[3]);
  };

  useEffect(() => {
    const randIndex = getRandomNumber(0, BOX_SHADOW_COUNT - 1);
    updateStatePixels(gridItems[randIndex]);
    setSelectedExampleNo(randIndex);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Set threshold to 300px, adjust if needed
      if (scrollPosition > 300) {
        setIsOverlay(true);
      } else {
        setIsOverlay(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onSelectCardTypeHandler = (value: number) => {
    setSelectedCardIndex(value);
  };

  // Check if colorMode is defined (it may be undefined if not wrapped in ThemeProvider)
  if (!colorMode) {
    return <div>Error: ThemeContext is not provided!</div>;
  }

  const backgroundColor = colorMode?.isDarkMode()
    ? theme.palette.background.paper
    : '#FFF';

  // Mapping of selectedCardIndex to the corresponding BoxShadowCard component
  const cardComponents = [
    BoxShadowCard,
    BoxShadowCardV2,
    BoxShadowCardV3,
    BoxShadowCardV4,
  ];

  const SelectedCard = cardComponents[selectedCardIndex]; // Logs the current breakpoint values

  const gridSize = getGridSize(selectedCardIndex);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{ mt: 4, p: { xs: 2, sm: 3 } }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={(theme) => ({ maxWidth: 600 })}
          >
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontWeight: 200,
                fontFamily: 'inherit',
                pb: 2,
                textAlign: 'center',
              })}
            >
              CSS{' '}
              <b
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }}
              >
                box-shadow
              </b>{' '}
              Generator
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({ textAlign: 'center' })}
            >
              A list of randomly generated{' '}
              <b
                style={{
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }}
              >
                box-shadows
              </b>{' '}
              . Select a ‘card type’ from the dropdown in the editor to preview
              how the shadow appears on different cards. Then, use the controls
              to see how the changes affect the main box above.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction={isTablet ? 'column' : 'row'}
          spacing={4}
          justifyContent="center"
          sx={(theme) => ({
            mb: 8,
            px: { md: 0, lg: 4 },
          })}
        >
          <Grid item xs={4} sm={4} md={6} lg={4}>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                p: 4,
                borderRight: '1px solid #dfdfdf',
              }}
            >
              {/* Content that should stay at the top */}
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={(theme) => ({
                    height: '1000px !important',
                    maxHeight: '1000px !important',
                    margin: 'auto',
                    width: '100%',
                    display: 'flex', // Add flex display
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content (if needed)
                  })}
                >
                  {cardComponents[selectedCardIndex] &&
                    React.createElement(cardComponents[selectedCardIndex], {
                      index: selectedExampleNo,
                      borderVal: mainBoxShadow,
                      colorVal: colorVal,
                      showBorder: showBorder,
                      hOffset: hOffset,
                      vOffset: vOffset,
                      blur: blur,
                      spread: spread,
                      isMainCard: true,
                    })}
                </Grid>
                <Grid item xs={12}>
                  <BoxShadowEditor
                    isOverlay={isOverlay}
                    selectedCardIndex={selectedCardIndex}
                    onSelectCardTypeHandler={onSelectCardTypeHandler}
                    setShowBorder={setShowBorder}
                    cardStyleString={cardStyleString}
                    sliderValue={sliderValue}
                    handleSliderChange={handleSliderChange}
                    showBorder={showBorder}
                    hOffset={hOffset}
                    setHOffset={setHOffset}
                    vOffset={vOffset}
                    setVOffset={setVOffset}
                    blur={blur}
                    setBlur={setBlur}
                    spread={spread}
                    setSpread={setSpread}
                    copyValue={copyValue}
                    setCopyValue={setCopyValue}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={8} sm={8} md={6} lg={8}>
            <Box
              sx={(theme) => ({
                p: isMobile ? 4 : 8,
                m: 0,
                borderRadius: 2,
              })}
            >
              {/* <Grid
                container
                direction="row"
                spacing={isMobile ? 4 : isTablet ? 4 : 12}
                sx={(theme) => ({ px: { md: 2, lg: 2, xl: 16 } })}
              > */}
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{ height: '100%' }}
                spacing={isMobile ? 4 : isTablet ? 4 : 12}
                // sx={(theme) => ({ px: { md: 2, lg: 2, xl: 16 } })}
              >
                {Array.from({ length: BOX_SHADOW_COUNT }, (_, index) => {
                  return (
                    <Grid
                      item
                      key={index}
                      xs={gridSize.xs}
                      sm={gridSize.sm}
                      md={gridSize.md}
                      lg={gridSize.lg}
                      xl={gridSize.xl}
                      sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'center', // Aligns horizontally
                        alignItems: 'center', // Aligns vertically if needed
                      })}
                    >
                      {/* <Fade
                    duration={800}
                    delay={index * 30}
                    triggerOnce
                    direction="up"
                  > */}
                      <SelectedCard
                        index={index}
                        borderVal={gridItems[index]}
                        colorVal={colorVal}
                        showBorder={showBorder}
                        onClick={onChildClickUpdateMainHandler}
                        handleClick={handleClick}
                      />
                      {/* </Fade> */}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>

          <Snackbar
            open={open}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              Selected Example #{selectedExampleNo + 1}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const updateBoxShadowColor = (
  borderVal,
  colorVal,
  hOffset,
  vOffset,
  blur,
  spread
) => {
  let tempStr = borderVal;
  if (hOffset) {
    tempStr = `${colorVal} ${hOffset}px ${vOffset}px ${blur}px ${spread}px`;
  }
  return borderVal;
};

const generateRandomBoxShadow = (colorVal: string) => {
  // Generate random numbers for the pixel values
  const hOffset = randNumb(8); // e.g., -10px to 10px
  const vOffset = randNumb(8); // e.g., -20px to 20px
  const blur = Math.floor(Math.random() * 6); // e.g., 0px - 20px
  const spread = Math.floor(Math.random() * 3); // e.g., 0px - 5px

  const hOffset2 = randNumb(10); // e.g., -10px to 10px
  const vOffset2 = randNumb(10); // e.g., -20px to 20px
  const blur2 = Math.floor(Math.random() * 6); // e.g., 0px - 20px
  const spread2 = Math.floor(Math.random() * 3); // e.g., 0px - 5px

  const useSecondShadow = getWeightedRandomNumber(0, 2, 2);
  // console.log('useSecondShadow: ', useSecondShadow);

  // const useInset = getRandomNumber(0, 1);
  const useInset = 0;
  // console.log('useInset: ', useInset);

  //   const px5 = Math.floor(Math.random() * 10);
  //   const px6 = Math.floor(Math.random() * 20);
  //   const px7 = Math.floor(Math.random() * 10);
  //   const px8 = Math.floor(Math.random() * 5);

  //   const left = Math.floor(Math.random() * 5);
  //   const right = Math.floor(Math.random() * 5);
  // const boxShadow = `${getRgbaFromNumber(getWeightedRandomNumber(5, 80, 2), Number(Math.random().toFixed(2)))} ${hOffset}px ${hOffset}px ${blur}px ${spread}px ${useInset ? 'inset' : ''} ${useSecondShadow ? `, ${getRgbaFromNumber(getWeightedRandomNumber(5, 80, 2), Number(Math.random().toFixed(2)))} ${hOffset2}px ${hOffset2}px ${blur2}px ${spread2}px ${useInset ? 'inset' : ''}` : ''}`;
  const boxShadow = `rgba(50, 50, 93, 0.25) ${hOffset}px ${hOffset}px ${blur}px ${spread}px ${useInset ? 'inset' : ''}`;
  // console.log('boxShadow: ', boxShadow);

  // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  return boxShadow;
  // return `${colorVal} ${hOffset}px ${hOffset}px ${blur}px ${spread}px, ${colorVal} 2px 2px 2px`;
  // return `${colorVal} ${px1}px ${px2}px ${px3}px ${px4}px`;
};

const randNumb = (range) => {
  return (
    (Math.random() < 0.5 ? -1 : 1) * Number((Math.random() * 10).toFixed(1))
  );
};

const getGridSize = (selectedCardIndex: number) => {
  console.log('selectedCardIndex: ', selectedCardIndex);
  switch (selectedCardIndex) {
    case 0:
      return { xs: 6, sm: 6, md: 12, lg: 6, xl: 4 };
    case 1:
      return { xs: 12, sm: 6, md: 12, lg: 6, xl: 4 };
    case 2: // good
      return { xs: 12, sm: 12, md: 12, lg: 6, xl: 4 };
    case 3:
      return { xs: 12, sm: 6, md: 12, lg: 6, xl: 4 };
    default:
      return { xs: 12, sm: 6, md: 12, lg: 6, xl: 4 };
  }
};

// box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

export default BoxShadowExamples;
