// import { ThemeContext, useTheme } from '@emotion/react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

interface PasswordGeneratorHeaderProps {}

const PasswordGeneratorHeader: FC<PasswordGeneratorHeaderProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{ mt: 4, p: { xs: 2, sm: 3 } }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({ maxWidth: 600 })}
      >
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            fontWeight: 200,
            fontFamily: 'inherit',
            pb: 2,
            textAlign: 'center',
          })}
        >
          <b
            style={{
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            }}
          >
            Password
          </b>{' '}
          Generator
        </Typography>
        <Typography variant="body1" sx={(theme) => ({ textAlign: 'center' })}>
          Generate a secure password based on best security practices. Choose
          the length of your password, select the character types (letters,
          numbers, symbols), and customize it to suit your needs.
        </Typography>
      </Stack>
    </Box>
  );
};

export default PasswordGeneratorHeader;
