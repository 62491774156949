import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Slider,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  ToggleButton,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import React, { useContext, useEffect } from 'react';
import { ThemeContext, ThemeContextQuery } from '../../themes/MyThemeProvider';
import { getThemeColour } from '../../Utils/StringUtils';
import { useLocation } from 'react-router-dom';

type SwitchProps = {
  show: boolean;
};

export const Item = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
}));

export const SectionContainerBox = styled(Box)(({ theme, bgcolor }) => ({
  padding: '60px 10px',
  // backgroundColor: `${(props: any) => props.bgcolor}`,
}));

export const SectionContainerBoxOther = styled(Box)(({ theme, bgcolor }) => ({
  padding: '60px 0px',
  // backgroundColor: `${theme.palette.primary.main}`,
}));

export const GridPadding2 = styled(Grid)(({ theme, maxWidth }) => ({
  // Responsive styling
  //   padding: theme.spacing(1),
  //   [theme.breakpoints.down("md")]: {
  //     backgroundColor: red[500],
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     backgroundColor: blue[500],
  //   },
  //   [theme.breakpoints.up("lg")]: {
  // backgroundColor: green[500],
  // padding: "0px 420px",
  margin: '0 auto',
  maxWidth: `${(props: any) => props.maxWidth}`,
  //   },
}));

export const TitleLanding = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontWeight: 100,
}));

export const PageTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  // maxWidth: 400,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 100,
  fontSize: '2.5rem',
  // borderBottom: "2px solid #696969",
}));

export const ToggleButtonTheme = styled(ToggleButton)(({ theme }) => ({
  // ...theme.typography.h1,
  // fontFamily: "Nunito Sans",
  // padding: theme.spacing(1),
  // textAlign: "center",
  // color: "white",
  // fontWeight: 100,
  textTransform: 'capitalize',
  border: 'none',
  borderRadius: 50,
}));

const style = {
  fontSize: '10px',
  mx: 0,
  borderRadius: 50,
  border: 'none',
};

export const HomePageTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  margin: '20px 0px',
  fontSize: '1.2rem !important',
}));

// how to change theme and modes? check below:
// this is a Component that'll on button click
// read the colorMode from context (which had our two functions to change theme and mode)
// use colorMode to call the toggleColorMode function
export const ThemeModeSwitch = ({ show }: SwitchProps) => {
  const theme = useTheme();
  const colorMode = useContext(ThemeContext);
  return (
    <ToggleButtonTheme
      sx={{ ...style }}
      value="check"
      onChange={colorMode?.toggleColorMode}
    >
      {/* {show && "Change Mode"} &nbsp; ({theme.palette.mode}) */}
      {theme.palette.mode === 'dark' && (
        <LightModeIcon sx={{ color: `${theme.palette.primary.main}` }} />
      )}
      {theme.palette.mode === 'light' && (
        <DarkModeIcon sx={{ color: `${theme.palette.primary.main}` }} />
      )}
    </ToggleButtonTheme>
  );
};
// this is a Component that'll on button click
// read the colorMode from context (which had our two functions to change theme and mode)
// use colorMode to call the shuffleColorTheme function
export const ThemeSwitch = ({ show }: SwitchProps) => {
  const theme = useTheme();
  const colorMode = React.useContext(ThemeContext);
  const tcq = useContext(ThemeContextQuery);
  return (
    <ToggleButtonTheme
      value={'check'}
      sx={{ ...style }}
      onChange={colorMode.shuffleColorTheme}
    >
      {/* {show && "Change Theme"} &nbsp; ({getThemeColour(tcq.theme)}) */}
      <ColorLensIcon sx={{ color: `${theme.palette.primary.main}` }} />
    </ToggleButtonTheme>
  );
};

export const ToggleShowThemeComp = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ThemeContext);
  const tcq = useContext(ThemeContextQuery);
  // console.log('tcq', tcq);
  return (
    <ToggleButtonTheme value={'check'} onChange={colorMode.toggleShowThemeComp}>
      {tcq.showThemeComp && (
        <VisibilityIcon sx={{ color: `${theme.palette.primary.main}` }} />
      )}
      {!tcq.showThemeComp && (
        <VisibilityOffIcon sx={{ color: `${theme.palette.primary.main}` }} />
      )}
    </ToggleButtonTheme>
  );
};

// see? because of context api, we didn't have to pass those functions all the way down till here. We
// can just React.useContext(Context);
// this is a way to create styled MUI components with our theme values
// here I want the input field to have font color I've chosen for containerSecondary
// and background color of containerSecondary
// I could pick current themes colors like theme.palette.primary.main
// or theme.palette.upvote.main etc
const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  color: theme?.palette?.containerSecondary?.contrastText,
  backgroundColor: theme?.palette?.containerSecondary?.main,
  '& .MuiInputBase-root': {
    color: theme?.palette?.containerSecondary?.contrastText,
  },
  '& .MuiInputLabel-root': {
    color: theme?.palette?.containerSecondary?.contrastText,
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const ThemeComponents = () => {
  // we can also get theme this way
  const theme = useTheme();
  return (
    <Card sx={{ borderBottom: `2px solid ${theme.palette.divider}` }}>
      <CardContent>
        <Grid container direction="row">
          <Grid xs={6}>
            <Item>
              <Stack direction="column" spacing={5}>
                <Stack direction="row" spacing={5}>
                  {/* Our buttons to toggle theme and modes */}
                  <ThemeModeSwitch show />
                  <ThemeSwitch show />
                </Stack>
                <Stack direction="row" spacing={5}>
                  <Typography variant="h6">Buttons</Typography>
                  <Button variant={'contained'} color={'primary'}>
                    Primary Button
                  </Button>
                  <Button variant={'contained'} color={'secondary'}>
                    Secondary Button
                  </Button>
                  <Button variant={'contained'} color={'upvote'}>
                    Custom Button
                  </Button>
                </Stack>
                <Stack direction="row" spacing={5}>
                  <Typography variant="h6">Textfields</Typography>
                  <TextField value={'Un styled text field'} color={'primary'} />
                  <StyledTextField
                    value={'styled text field'}
                    multiline
                    size="small"
                    rows={4}
                  />
                </Stack>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Box with Text</Typography>
                  <Box
                    // Here I am using the theme variables to style my box element to container secondary
                    sx={{
                      color: theme?.palette?.containerSecondary?.contrastText,
                      backgroundColor: theme?.palette?.containerSecondary?.main,
                      padding: 1,
                    }}
                  >
                    <Typography>Use Theme</Typography>
                  </Box>
                </Stack>
              </Stack>
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <Stack direction="column" spacing={5}>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Checkbox</Typography>
                  <Checkbox color={'primary'} defaultChecked />
                  <Checkbox color={'error'} defaultChecked />
                  <Checkbox color={'secondary'} defaultChecked />
                </Stack>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Sliders</Typography>
                  <Slider />
                </Stack>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Circular Spinners</Typography>

                  <CircularProgress color={'downvote'} />
                </Stack>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Chips</Typography>
                  <Chip label="Sample Chip" color={'primary'} />
                  <Chip label="Sample Chip" color={'secondary'} />
                </Stack>
                <Stack alignItems={'center'} direction="row" spacing={5}>
                  <Typography variant="h6">Switches</Typography>
                  <Switch {...label} defaultChecked />
                  <Switch {...label} />
                  <Switch {...label} disabled defaultChecked />
                  <Switch {...label} disabled />
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
