// import '@fontsource/nunito-sans'; // Defaults to weight 400
// import '@fontsource/nunito-sans/400-italic.css'; // Specify weight and style
// import '@fontsource/nunito-sans/400.css'; // Specify weight
// import '@fontsource/nunito-sans/700-italic.css'; // Specify weight and style
// import '@fontsource/nunito-sans/700.css'; // Specify weight
import { Chip, Grid, styled } from '@mui/material';
import beach8 from '../../../../resources/images/beach8.jpg';
import {
  HomePageTypography,
  Item,
  SectionContainerBox,
} from '../../../General/Components';
import { Fade } from 'react-awesome-reveal';
import { useState } from 'react';
import { getFirebaseImage } from '../../../../Utils/FirebaseUtils';
// @ts-ignore
// import Fade from "react-awesome-reveal/Fade";

type AboutProps = {
  text?: string;
  backgroundColour: string;
};

export const TypographyTitle = styled('h6')(({ theme }) => ({
  ...theme.typography.h6,
  fontFamily: 'Nunito Sans',
  paddingLeft: theme.spacing(2),
  margin: '16px 0px 16px 0px',
  textAlign: 'left',
  fontWeight: 'bold',
  borderLeft: `3px solid ${theme.palette.primary.main}`,
}));

export const AboutContainer = styled(Grid)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: 16,
  padding: 6,
}));

export const AboutGridContainer = styled(Grid)(({ theme }) => ({
  marginLeft: 11,
}));

export const AboutText = styled('p')(({ theme }) => ({
  ...theme.typography.body1,
  fontFamily: 'Nunito Sans',
  padding: '16px 16px 16px 24px',
  textAlign: 'left',
}));

export const AboutChip = styled(Chip)(({ theme }) => ({
  // backgroundColor: "#ffffff",
  // color: theme.palette.text.primary,
  // border: `1px solid ${theme.palette.primary.main}`,
}));

const About = ({ text, backgroundColour }: AboutProps) => {
  const [aboutImg, setAboutImg] = useState<string>('');
  getFirebaseImage('/beach/beach8-min.jpg', setAboutImg);

  return (
    <SectionContainerBox bgcolor={backgroundColour}>
      <AboutContainer container direction="row" sx={{ px: { xs: 0, lg: 20 } }}>
        {/* <Grid md={1}></Grid> */}
        <Grid xs={12} sm={12} md={6}>
          <Item>
            <div>
              <img
                src={aboutImg}
                alt={aboutImg}
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Item>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={5}>
          <Item>
            <Fade>
              <TypographyTitle>About Me</TypographyTitle>
              {/* <AboutText
                style={{
                  fontWeight: 'bold',
                  margin: '0px 0px',
                  padding: '0px 22px',
                }}
              >
                No one has ever been on their death bed wishing they had worked
                more...
              </AboutText> */}
              <HomePageTypography
                style={{
                  margin: '0px 0px',
                }}
              >
                I’m passionate about creating moments of connection, whether
                through shared experiences outdoors, a new creative project, or
                simply enjoying a good laugh with friends. I believe that life
                is about finding balance—between work and play, quiet reflection
                and active pursuits—and I strive to bring that balance into
                every area of my life.
                <br />
                <br />
                For me, positivity is more than a mindset; it’s something I
                consciously bring to my interactions. I take pride in creating a
                welcoming environment where people feel uplifted and
                appreciated. There’s a unique satisfaction in knowing I can
                contribute a bit of joy to someone else’s day, and I make it my
                mission to do so as often as I can.
              </HomePageTypography>
            </Fade>
          </Item>
        </Grid>
        <Grid md={1}></Grid>
      </AboutContainer>
    </SectionContainerBox>
  );
};

export default About;
