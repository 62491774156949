import { FC, useEffect } from 'react';
import { getRandomNumber } from '../../../../../Utils/NumberUtils';
import { getRandomStringFromArr } from '../../../../../Utils/StringUtils';

interface CharacterProps {
  char: string;
  isRunning: boolean;
  index: number;
  useUppercase: boolean;
  useNumbers: boolean;
  useSymbols: boolean;
  charactersToUse: string[];
  updateCharIndex: (index: number, char: string) => void;
}

const Character: FC<CharacterProps> = ({
  isRunning,
  index,
  charactersToUse,
  updateCharIndex,
}) => {
  const delay = getRandomNumber(200, 400);

  useEffect(() => {
    let interval: any;

    if (isRunning)
      interval = setInterval(() => {
        updateCharIndex(index, getRandomStringFromArr(charactersToUse));
      }, delay);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, charactersToUse]);

  return <></>;
};

export default Character;
