import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Character from './Character';

interface PasswordProps {
  password: string[];
  passwordBgColor: string;
  updateCharIndex: (index: number, char: string) => void;
  isRunning: boolean;
  useUppercase: boolean;
  useNumbers: boolean;
  useSymbols: boolean;
  charactersToUse: string[];
}

const Password: FC<PasswordProps> = ({
  password,
  passwordBgColor,
  updateCharIndex,
  isRunning,
  useNumbers,
  useSymbols,
  useUppercase,
  charactersToUse,
}) => {
  return (
    <Box
      sx={(theme) => ({
        mt: 4,
        p: { xs: 2, sm: 4 },
        py: { xs: 4 },
        bgcolor: passwordBgColor,
      })}
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        {password.map((char, index) => (
          <Character
            key={index}
            char={char}
            index={index}
            updateCharIndex={updateCharIndex}
            isRunning={isRunning}
            useUppercase={useUppercase}
            useNumbers={useNumbers}
            useSymbols={useSymbols}
            charactersToUse={charactersToUse}
          />
        ))}
        {
          <Typography
            sx={(theme) => ({
              fontSize: {
                xs: password.length < 16 ? '1.75rem' : '1.3rem',
                sm: '2.2rem',
                md: '2.4rem',
                lg: '3.4rem',
                xl: '4rem',
              },
              color: '#FFF',
              textAlign: 'center',
              fontWeight: 400,
              letterSpacing: 8,
              // fontFamily: "'Courier New', Courier, monospace !important", // Use a monospaced font
              // fontFamily: "'Major Mono Display', monospace !important",
              fontFamily: "'Kode Mono', monospace !important",
              whiteSpace: 'nowrap', // Prevent wrapping
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipses for overflow
              width: '100%', // Set a fixed width
              maxWidth: 2200,
            })}
          >
            {password.join('')}
          </Typography>
        }
      </Stack>
    </Box>
  );
};

export default Password;
