import PinIcon from '@mui/icons-material/Pin';
import RampLeftIcon from '@mui/icons-material/RampLeft';
import Timer10SelectIcon from '@mui/icons-material/Timer10Select';
import {
  Box,
  Card,
  Grid,
  Link,
  Skeleton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getFirebaseImage } from '../../../../Utils/FirebaseUtils';
import { ThemeContext } from '@emotion/react';

interface AppsHomeProps {}

const AppsHome: React.FC<AppsHomeProps> = () => {
  const [seconds, setSeconds] = useState(3);
  const [timerEnded, setTimerEnded] = useState(false);

  const [numbersImg, setNumbersImg] = useState<string>('');
  const [roadImg, setRoadImg] = useState<string>('');
  const [stopwatchImg, setStopwatchImg] = useState<string>('');

  useEffect(() => {
    getFirebaseImage('/games/numbers.png', setNumbersImg);
    getFirebaseImage('/games/road.png', setRoadImg);
    getFirebaseImage('/games/stopwatch.png', setStopwatchImg);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 200);
      return () => clearInterval(interval); // Clean up interval on unmount
    } else {
      setTimerEnded(true);
    }
  }, [seconds]);

  <div>
    <h1>Time left: {seconds} seconds</h1>
    {timerEnded && <p>Time is up!</p>}
  </div>;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({ px: { xs: 5 } })}
    >
      <Grid item xs={0} md={3}></Grid>
      <Grid
        item
        xs={12}
        md={6}
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({ width: '100%' })}
      >
        <Stack
          direction="column"
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({ py: 10, width: '100%' })}
        >
          <AppItem
            title="Countdown Timer"
            link="/games/countdown-timer"
            Icon={Timer10SelectIcon}
            timerEnded={timerEnded}
            image={stopwatchImg}
            description="Put your visual skills to the ultimate test in a thrilling race against the clock! Sharpen your focus, hone your precision, and see if you can keep up in this fast-paced game of recognition and accuracy. Are you quick enough to spot the details and beat the timer? Let the challenge begin!"
          />
          <AppItem
            title="Number Recognition"
            link="/games/number-recognition"
            Icon={PinIcon}
            timerEnded={timerEnded}
            image={numbersImg}
            description="Race against the clock! Click on the numbers in the grid that match the question at the top. Enjoy the challenge!"
          />
          <Box
            sx={(theme) => ({
              width: '100%',
              height: 10,
              borderBottom: '1px solid #eee',
            })}
          ></Box>
          <Typography variant="h4"> Coming Soon...</Typography>
          <AppItem
            title="Path Finder"
            link="/games/pathfinder"
            Icon={RampLeftIcon}
            timerEnded={timerEnded}
            image={roadImg}
            description=""
          />
        </Stack>
      </Grid>
      <Grid item xs={0} md={3}></Grid>
    </Grid>
  );
};

export const AppItemStyled = styled(Card)(({ theme, colorMode }) => ({
  width: '100%',
  padding: 34,
  boxShadow: `${theme.palette.background.paper} 2px 2px 8px 4px`,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  backgroundColor: `${theme.palette.background.default}`,
  display: 'flex',
}));

const AppItem = ({ title, link, Icon, timerEnded, image, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const colorMode = useContext(ThemeContext);

  return (
    <AppItemStyled colorMode={colorMode}>
      <Link
        href={link}
        underline="none"
        sx={(theme) => ({ color: theme.palette.text.primary, width: '100%' })}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={4}
          justifyContent={isMobile ? 'center' : 'flex-start'}
          // alignItems=""
          sx={(theme) => ({ width: '100%' })}
        >
          {/* {timerEnded && (
            <Box sx={(theme) => ({ width: 150, height: 150, bgcolor: '#fff' })}>
              <img
                src={image}
                alt={image}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          )} */}
          {!timerEnded && (
            <Stack
              direction="column"
              spacing={2}
              sx={(theme) => ({ width: '100%' })}
            >
              <Skeleton variant="rounded" width="30%" height={60} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <br />
            </Stack>
          )}
          {timerEnded && (
            <Stack
              direction="column"
              spacing={2}
              sx={(theme) => ({ width: '100%' })}
            >
              <Typography
                variant="h4"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {title}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </Stack>
          )}
        </Stack>
      </Link>
    </AppItemStyled>
  );
};

export const IconBox = styled(Box)(({ theme }) => ({
  padding: 6,
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default AppsHome;
